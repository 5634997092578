import { useState } from 'react';
import { Bars3Icon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import { Drawer, useDrawer } from '~/components/Drawer';
import { Suspense, useEffect } from 'react';
import { Await, useMatches, useFetchers, Link } from '@remix-run/react';
import { CartLineItems, CartActions, CartSummary } from '~/components/Cart';

export function Layout({ children, title }) {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isOpen, openDrawer, closeDrawer } = useDrawer();
  const fetchers = useFetchers();
  const [root] = useMatches();
  const cart = root.data?.cart;

  // Grab all the fetchers that are adding to cart
  const addToCartFetchers = [];
  for (const fetcher of fetchers) {
    if (fetcher?.formData?.get('cartAction') === 'ADD_TO_CART') {
      addToCartFetchers.push(fetcher);
    }
  }
  // When the fetchers array changes, open the drawer if there is an add to cart action
  useEffect(() => {
    if (isOpen || addToCartFetchers.length === 0) return;
    openDrawer();
  }, [addToCartFetchers]);
  return (
    <div>
      <div
        className="tracking-wide leading-7 bg-gray-800 text-gray-200 break-words bg-fixed border-b border-white border-solid"
        role="region"
        aria-label="Announcement"
      >
        <div className="py-0 px-6 my-0 mx-auto leading-7 break-words md:px-20 md:py-0 max-w-[1200px]">
          <p className="py-4 px-0 m-0 font-sans text-xs not-italic font-normal tracking-widest leading-4 text-center md:text-xs">
            <span className="tracking-wider">
              Free shipping for UK orders £99 and above
            </span>
          </p>
        </div>
      </div>
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex flex-1">
            <div className="hidden lg:flex lg:gap-x-12">

            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-16 w-auto" src="/logo.png" alt="" />
          </Link>
          <div className="flex flex-1 justify-end">
            <CartHeader cart={cart} openDrawer={openDrawer} />
          </div>
        </nav>
        <section className="hidden lg:grid mx-auto overflow-hidden">
          <div className="flex items-center justify-center px-7 py-5 bg-white">
            <div className="w-auto">
              <div className="flex flex-row items-center">
                <div className="w-auto">
                  <ul className="flex flex-row justify-between gap-8">
                    <li>
                      <Link to="/">Shop</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6">
            <div className="flex items-center justify-between">
              <div className="flex flex-1">
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-16 w-auto" src="/logo.png" alt="" />
              </Link>
              <div className="flex flex-1 justify-end">
                <CartHeader cart={cart} openDrawer={openDrawer} />
              </div>
            </div>
            <div className="mt-6 space-y-2">
              <Link
                to="/"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                onClick={() => setMobileMenuOpen(false)}
              >
                Shop
              </Link>
              <Link
                to="/contact"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                onClick={() => setMobileMenuOpen(false)}
              >
                Contact
              </Link>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <main>{children}</main>
      <Drawer open={isOpen} onClose={closeDrawer}>
        <CartDrawer cart={cart} close={closeDrawer} />
      </Drawer>
      <footer className="bg-gray-800">
        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
          <nav className="-mb-6 flex flex-col md:flex-row items-center justify-center" aria-label="Footer">
            <Link to="https://lazovski.uk/about/" target="_blank" className="text-sm leading-6 text-white mr-4 block">About</Link>
            <Link to="https://lazovski.uk/press/" target="_blank" className="text-sm leading-6 text-white mr-4 block">Press</Link>
            <Link to="/privacy-policy" className="text-sm leading-6 text-white mr-4 block">Privacy Policy</Link>
            <Link to="/refund-policy" className="text-sm leading-6 text-white mr-4 block">Refund Policy</Link>
            <Link to="/shipping-policy" className="text-sm leading-6 text-white mr-4 block">Shipping Policy</Link>
            <Link to="/terms-of-service" className="text-sm leading-6 text-white mr-4 block">Terms of Service</Link>
          </nav>
          <p className="mt-10 text-center text-xs leading-5 text-white">
            &copy; {new Date().getFullYear()} Ana Lazovski. All rights reserved.
          </p>
        </div>
      </footer >
    </div>
  );
}
function CartHeader({ cart, openDrawer }) {
  return (
    <Suspense>
      <Await resolve={cart}>
        {(data) => (
          <button
            className="relative ml-auto flex items-center justify-center w-8 h-8"
            onClick={openDrawer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <title>Cart</title>
              <path
                fillRule="evenodd"
                d="M8.125 5a1.875 1.875 0 0 1 3.75 0v.375h-3.75V5Zm-1.25.375V5a3.125 3.125 0 1 1 6.25 0v.375h3.5V15A2.625 2.625 0 0 1 14 17.625H6A2.625 2.625 0 0 1 3.375 15V5.375h3.5ZM4.625 15V6.625h10.75V15c0 .76-.616 1.375-1.375 1.375H6c-.76 0-1.375-.616-1.375-1.375Z"
              ></path>
            </svg>
            {data?.totalQuantity > 0 && (
              <div className="text-contrast bg-red-500 text-white absolute bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px">
                <span>{data?.totalQuantity}</span>
              </div>
            )}
          </button>
        )}
      </Await>
    </Suspense>
  );
}

function CartDrawer({ cart, close }) {
  return (
    <Suspense>
      <Await resolve={cart}>
        {(data) => (
          <>
            {data?.totalQuantity > 0 ? (
              <>
                <div className="flex-1 overflow-y-auto">
                  <div className="flex flex-col space-y-7 justify-between items-center md:py-8 md:px-12 px-4 py-6">
                    <CartLineItems linesObj={data.lines} />
                  </div>
                </div>
                <div className="w-full md:px-12 px-4 py-6 space-y-6 border border-1 border-gray-00">
                  <CartSummary cost={data.cost} />
                  <CartActions checkoutUrl={data.checkoutUrl} />
                </div>
              </>
            ) : (
              <div className="flex flex-col space-y-7 justify-center items-center md:py-8 md:px-12 px-4 py-6 h-screen">
                <h2 className="whitespace-pre-wrap max-w-prose font-bold text-4xl">
                  Your cart is empty
                </h2>
                <button
                  onClick={close}
                  className="inline-block rounded-sm font-medium text-center py-3 px-6 max-w-xl leading-none bg-black text-white w-full"
                >
                  Continue shopping
                </button>
              </div>
            )}
          </>
        )}
      </Await>
    </Suspense>
  );
}
